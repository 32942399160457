@import '../../../sass/foundation/mixin';

.SignatureCanvas {
  @media screen {
    // ブラウザ用
    background-color: #f5f5f5;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    padding-top: 3rem;
  }
  @media (max-width: 768px) {
    width: 600px;
    height: 300px;
  }

  &-frame {
    // レンダリング後にフレームサイズが変わると, タッチした箇所と線画描画される箇所がズレるので, 固定
    width: 960px;
    height: 180px;
    border: 2px solid #afaeae;
    position: relative;
    display: flex;
    justify-content: center;
    background-color: white;

    @media (max-width: 768px) {
      width: 480px;
      height: 180px;
    }
  }

  &-actions {
    width: 100%;
    height: 20%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    &-editGroup {
      width: 30%;
      height: 70%;
      display: flex;
      justify-content: space-around;
      align-items: center;

      Button {
        width: 15%;
        height: 100%;
        min-width: 60px;
        min-height: 30px;
      }
    }
    &-SubmitResetGroup {
      width: 30%;
      height: 70%;
      display: flex;
      justify-content: space-around;
      align-items: center;

      Button {
        width: 25%;
        height: 100%;
        min-width: 60px;
        min-height: 30px;
      }
    }
  }
  &-alartImgLength {
    width: 50%;
    height: 20%;
    font-size: normal;
    font-weight: bold;
    color: red;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}
