@use '../../../sass/foundation/mixin';

// ==================================================
// Project
// ==================================================

// Modal
// ==========================================================================

.Modal {
  @include mixin.media-all-pc {
    position: absolute;
  }

  @include mixin.media-all-sp {
    position: fixed;
    width: 100%;
    height: 100%;
  }

  top: 0;
  left: 0;
  z-index: 12000;

  button.Modal__nav-right[disabled] {
    color: #ccc !important;
    cursor: default;
  }

  @at-root {
    .Modal__bg {
      @include mixin.media-all-pc {
        position: fixed;
        top: 0;
        width: 100vw;
        height: 100vh;
        background-color: #000;
        opacity: 0.5;
      }

      @include mixin.media-all-sp {
        display: none;
      }
    }

    .Modal__content {
      @include mixin.media-all-pc {
        position: fixed;
        top: 50vh;
        left: 50vw;
        width: 55rem;
        margin-right: auto;
        margin-left: auto;
        background-color: #fff;
        border: 1px solid #e5e5e5;
        border-radius: 8px;
        box-shadow: 0px 2px 5px #444;
        overflow: hidden;
        transform: translate(-50%, -50%);
      }
      @include mixin.media-all-sp {
        height: 100%;
      }
    }

    .Modal__nav {
      @include mixin.media-all-pc {
        padding-top: 1.1rem;
        padding-right: 1.1rem;
        padding-left: 1.1rem;
        padding-bottom: 1.1rem;
      }

      @include mixin.media-all-sp {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 4.4rem;
        box-shadow: 0px 2px 5px rgba(136, 136, 136, 0.3);
        z-index: 100;
      }

      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      background-color: #ffffff;

      .Modal__nav-right {
        @include mixin.media-all-pc {
          padding-top: 0.2rem;
          right: 3.2rem;
          font-size: 1.6rem;
        }

        @include mixin.media-all-sp {
          right: 2.3rem;
          font-size: 1.2rem;
        }

        position: absolute;
        padding-top: 0.6rem;
        padding-right: 0.4rem;
        padding-left: 0.4rem;
        padding-bottom: 0.4rem;
        color: #0068ff;
        font-weight: 700;
      }

      .Modal__nav-left {
        @include mixin.media-all-pc {
          left: 1.2rem;
          font-size: 1.6rem;
          cursor: pointer;
        }

        @include mixin.media-all-sp {
          left: 1.2rem;
          font-size: 1.2rem;
        }

        position: absolute;
        padding-top: 0.6rem;
        padding-right: 0.4rem;
        padding-left: 0.4rem;
        padding-bottom: 0.4rem;
      }

      .Modal__title {
        @include mixin.media-all-pc {
          font-size: 2rem;
        }

        @include mixin.media-all-sp {
          font-size: 1.4rem;
        }

        color: #333;
        font-weight: 700;
      }

      .Modal__progress {
        @include mixin.media-all-pc {
          right: 3.6rem;
        }
        @include mixin.media-all-sp {
          right: 2.3rem;
        }
        position: absolute;
        top: 12px;
      }
    }

    .Modal__main {
      @include mixin.media-all-pc {
        max-height: 88vh;
        padding-right: 2rem;
        padding-left: 2rem;
      }
      @include mixin.media-all-sp {
        height: 100%;
        padding-top: 4.4rem;
      }

      background-color: #eee;
      overflow-y: auto;
      -webkit-overflow-scrolling: touch;

      .Modal__inner {
        @include mixin.media-all-pc {
          padding-top: 2.2rem;
          padding-bottom: 2.2rem;
        }

        margin-right: auto;
        margin-left: auto;
      }

      @at-root {
        .Modal__textarea {
          @include mixin.media-all-pc {
            width: 100%;
            height: 11em;
            padding-top: 0.4rem;
            padding-right: 1rem;
            padding-left: 1rem;
            padding-bottom: 0.4rem;
            border-radius: 6px;
            font-size: 1.8rem;
          }

          @include mixin.media-all-sp {
            width: 100%;
            height: 20em;
            padding: mixin.vw-value(20);
            font-size: 1.6rem;
          }

          border: none;

          &::placeholder {
            color: #c1c1c1;
          }
        }

        .Modal__input-text {
          @include mixin.media-all-pc {
            border-radius: 5px;
            font-size: 1.8rem;
          }

          @include mixin.media-all-sp {
            font-size: 1.6rem;
          }

          display: block;
          width: 100%;
          margin-top: 1.6rem;
          margin-bottom: 1.8rem;
          padding-top: 1.2rem;
          padding-right: 1rem;
          padding-left: 1rem;
          padding-bottom: 1.2rem;
          border: none;

          &::placeholder {
            color: #c1c1c1;
          }
        }

        .Modal__image-box {
          display: flex;
          flex-wrap: wrap-reverse;

          @include mixin.media-all-pc {
            padding-top: 0.6rem;
          }

          @include mixin.media-all-sp {
            padding-top: mixin.vw-value(15);
            padding-right: mixin.vw-value(35);
            padding-left: mixin.vw-value(35);
            padding-bottom: mixin.vw-value(35);
          }
        }

        .Modal__switchToggle {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-top: 1.6rem;
          margin-bottom: 1.8rem;
          padding-top: 1.2rem;
          padding-right: 1rem;
          padding-left: 1rem;
          padding-bottom: 1.2rem;
          background-color: #fff;

          p {
            @include mixin.media-all-pc {
              font-size: 1.8rem;
            }

            @include mixin.media-all-sp {
              font-size: mixin.vw-value(28);
            }
          }

          .Btn__switchtgl + .Btn__switchtgl-btn {
            width: 4.2rem;
            height: 2.2rem;
          }
        }

        .Modal__input-size {
          p {
            @include mixin.media-all-sp {
              padding-left: 1rem;
              font-weight: bold;
            }

            font-size: 1.5rem;
          }

          .Modal__input-size-inner {
            @include mixin.media-all-pc {
              border-radius: 5px;
            }

            margin-top: 0.6rem;
            padding-top: 1rem;
            padding-right: 1.2rem;
            padding-left: 1.2rem;
            padding-bottom: 1rem;
            background-color: #fff;

            dl {
              display: flex;
              flex-wrap: wrap;
              justify-content: flex-end;
              align-items: flex-end;
            }

            dt {
              @include mixin.media-all-pc {
                width: 30rem;
              }

              @include mixin.media-all-sp {
                width: 55vw;
              }

              margin-bottom: 0.5rem;
              font-size: 1.4rem;
              text-align: right;
              font-weight: bold;
            }

            dd {
              display: flex;
              align-items: flex-end;
              margin-top: 0.5rem;
              margin-left: 0.6em;
              font-size: 1.4rem;
            }

            span {
              margin-bottom: 0.5rem;
              margin-left: 0.6em;
              font-size: 1.3rem;
              font-weight: bold;
            }

            input {
              @include mixin.media-all-pc {
                font-size: 1.4rem;
              }

              @include mixin.media-all-sp {
                font-size: 1.6rem;
              }

              width: 10.3rem;
              padding-top: 0.7rem;
              padding-left: 1rem;
              padding-bottom: 0.7rem;
              border: none;
              border-radius: 3px;
              background-color: #eeeeee;
            }
          }
        }

        .Modal__image-add {
          @include mixin.media-all-pc {
            margin-top: 1.4rem;
            margin-right: 0.3rem;
            margin-left: 0.3rem;
          }

          @include mixin.media-all-sp {
            margin-top: mixin.vw-value(20);
            margin-right: mixin.vw-value(13);
            margin-left: mixin.vw-value(13);
          }

          outline: 1px solid mixin.$color-mediumGray;
          cursor: pointer;

          .image-wrap {
            img {
              @include mixin.media-all-pc {
                width: 11.9rem;
                height: 11.9rem;
              }

              @include mixin.media-all-sp {
                width: mixin.vw-value(200);
                height: mixin.vw-value(200);
              }
              object-fit: cover;
            }
          }

          &.-media {
            position: relative;

            .delete-btn {
              position: absolute;
              top: -0.7rem;
              right: -0.3rem;
              width: 1.8rem;
              height: 1.8rem;
              background-image: url('../../../../public//assets/img/common/icon/delete.svg');
              background-size: contain;
              background-repeat: no-repeat;
              cursor: pointer;
              z-index: 50;
            }
          }

          // here
          &.-pdf {
            @include mixin.media-all-pc {
              width: 11.9rem;
              height: 11.9rem;
            }

            @include mixin.media-all-sp {
              width: mixin.vw-value(200);
              height: mixin.vw-value(200);
            }

            position: relative;
            margin-top: 1.4rem;
            background-image: url('../../../../public//assets/img/messages/icon/pdf.svg');
            background-size: 2.8rem;
            background-repeat: no-repeat;
            background-position: center 44%;
            background-color: #f4f5f5;
            outline: 1px solid mixin.$color-mediumGray;

            p {
              position: absolute;
              bottom: 1rem;
              width: 100%;
              padding-right: 0.3rem;
              padding-left: 0.3rem;
              text-align: center;
              font-size: 1rem;
              letter-spacing: -0.01em;
              word-wrap: break-word;
              overflow-wrap: break-word;
            }
          }
        }

        .Modal__error {
          margin-top: 16px;
          font-size: 1.5rem;
          color: #ff8888;
          margin-left: auto;
          margin-right: auto;
          text-align: center;
        }
      }
    }

    // 家具家電リスト投稿Modal
    .Modal__content-furniture {
      .Modal__textarea {
        @include mixin.media-all-pc {
          height: 6em;
        }
        @include mixin.media-all-sp {
          height: 13rem;
        }
      }

      .Modal__image-box {
        @include mixin.media-all-pc {
          padding-top: 0;
        }

        .Modal__image-add {
          @include mixin.media-all-pc {
            margin-top: 0;
          }
        }
      }
    }
  }

  &.-flow-modal {
    @include mixin.media-all-sp {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .Modal__content {
      @include mixin.media-all-pc {
        width: 41.5rem;
      }

      @include mixin.media-all-sp {
        position: relative;
        width: 72vw;
        max-width: 50rem;
        min-width: 30rem;
        height: auto;
        min-height: 70vh;
        border-radius: 6px;
      }

      background-color: #fff;
    }

    .Modal__nav {
      @include mixin.media-all-sp {
        box-shadow: none;
      }

      height: auto;
      padding-top: 3.3rem;
      padding-bottom: 0.6rem;
      background-color: transparent;
      color: #333333;

      .Modal__nav-right {
        @include mixin.media-all-pc {
          width: 2rem;
          height: 2rem;
          top: 24px;
          right: 26px;
        }

        @include mixin.media-all-sp {
          width: mixin.vw-value(24);
          height: mixin.vw-value(24);
          top: 20px;
          right: 20px;
        }

        background-image: url('../../../../public//assets/img/home/icon/close.svg');
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        cursor: pointer;
      }
    }

    .Modal__main {
      background-color: transparent;
    }

    .Modal__inner {
      padding-bottom: 6.2rem;
    }

    .Modal__flow-list {
      @include mixin.media-all-pc {
        margin-left: 7.8rem;
      }
      @include mixin.media-all-sp {
        margin-top: 6vw;
        margin-left: 14vw;
      }

      li {
        @include mixin.media-all-pc {
          font-size: 2rem;
        }

        @include mixin.media-all-sp {
          font-size: mixin.vw-value(26);
        }

        display: flex;
        justify-content: flex-start;
        align-items: center;
        color: #333333;
        line-height: 2;

        & + li {
          margin-top: 0.4rem;
        }

        &::before {
          @include mixin.media-all-pc {
            width: 2.2rem;
            height: 2.2rem;
            margin-right: 2rem;
          }

          @include mixin.media-all-sp {
            width: mixin.vw-value(28);
            height: mixin.vw-value(28);
            margin-top: -0.2rem;
            margin-right: mixin.vw-value(24);
          }
          content: '';
          display: block;
          background-image: url('../../../../public//assets/img/home/icon/check_off.svg');
          background-size: cover;
          background-position: center;
          background-repeat: no-repeat;
        }

        &.-checked {
          &::before {
            background-image: url('../../../../public//assets/img/home/icon/check_on.svg');
          }
        }
      }
    }
  }
  &.-review-modal {
    @include mixin.media-all-sp {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .Modal__content {
      @include mixin.media-all-pc {
        width: 41.5rem;
      }

      @include mixin.media-all-sp {
        position: relative;
        width: 72vw;
        max-width: 100%;
        min-width: 30rem;
        height: auto;
        border-radius: 6px;
        padding: 1rem;
      }
      text-align: center;
      background-color: #fff;
      padding: 2rem;
    }

    .Modal__nav {
      position: relative;
      padding: 0;
      margin: 1rem;
      background-color: transparent;
      color: #333333;

      @include mixin.media-all-sp {
        box-shadow: none;
        margin: 0;
      }
    }

    .Modal__main {
      background-color: transparent;
      text-align: center;
      padding: 0;
    }

    .rating {
      font-size: 25px;
    }

    .dv-star-rating-star {
      margin-left: 10px;
    }

    .send-review {
      margin-top: 20px;
      font-size: 1.5rem;
      color: #ccc;
      cursor: default;
      @include mixin.media-all-sp {
        font-size: 1.4rem;
      }

      .send-button {
        width: 110px;
        height: 30px;
        @include mixin.media-all-sp {
          margin-bottom: 2px;
        }
      }

      &.-on {
        color: #2285fa;
        cursor: pointer;

        .send-button {
          &:hover {
            background-color: #d7eeff;
            border-radius: 7px;
          }
        }
      }
    }

    .not-review {
      width: 110px;
      height: 30px;
      font-size: 1.5rem;
      cursor: pointer;
      text-align: center;
      margin-top: 1rem;
      @include mixin.media-all-sp {
        font-size: 1.4rem;
      }

      &:hover {
        background-color: #eeeeee;
        border-radius: 7px;
      }
    }

    .review-line {
      border-bottom: solid 2px #ccc;
      padding-bottom: 10px;
    }

    textarea {
      width: 100%;
      height: 100px;
      margin: 2rem 0 0.5rem;
      padding: 0.5em;
      font-size: 1em;
      border: solid 2px #e1e3e8;
      border-radius: 4px;
      resize: none;
      @include mixin.media-all-sp {
        font-size: 1.6rem;
        margin: 1rem 0;
        &::placeholder {
          font-size: 1rem;
        }
      }
    }

    .terms-container {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .terms-text {
      color: gray;
    }

    .terms-text-left {
      text-align: left;
    }

    .terms-details-button {
      text-decoration: none;
      border-bottom: 0.5px solid rgba(128, 128, 128, 0.5);
      margin-left: 10px;
      min-width: auto;
      text-transform: none;
      color: gray;
    }
  }

  &.-column-review-modal {
    @include mixin.media-all-sp {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .column-modal-title {
      @include mixin.media-all-pc {
        font-size: 1.7rem;
      }

      @include mixin.media-all-sp {
        font-size: 1.4rem;
      }

      color: #333;
      font-family: 'HiraKakuProN-W6';
      font-weight: bold;
      margin-top: 10px;
    }

    .Modal__content {
      @include mixin.media-all-pc {
        width: 41.5rem;
      }

      @include mixin.media-all-sp {
        position: relative;
        width: 72vw;
        max-width: 100%;
        min-width: 30rem;
        height: auto;
        border-radius: 6.3px;
        padding: 1rem 1rem 2rem;
      }
      text-align: center;
      background-color: rgb(238, 236, 236);
      padding: 2rem;
    }

    .column-textarea {
      margin-top: 20px;
      margin-bottom: 10px;
      width: 100%;
      height: 20vh;
      background-color: white;
      resize: none;
      border: 1px solid #d6d6d6;
    }

    .send-button {
      @include mixin.media-all-pc {
        font-size: 1.7rem;
        padding: 10px 30px;
      }

      @include mixin.media-all-sp {
        font-size: 1.4rem;
        padding: 8px 20px;
        margin-bottom: 10px;
      }
      border-radius: 5px;
      font-weight: 700;
      color: #eee;
      margin-top: 10px;
      background-color: #d0d0d0;
      cursor: default;
      &.-on {
        background: #2285fa;
        cursor: pointer;
      }
    }

    .example-container {
      margin-bottom: 30px;
    }

    .column-example {
      text-align: left;
      font-weight: 700;
      margin-bottom: 5px;
      color: rgb(93, 93, 93);
    }

    .close-button {
      position: absolute;
      top: 10px;
      right: 10px;
      border: none;
      background-color: transparent;
      color: #7b7b7b;
      cursor: pointer;
      font-size: 16px;

      &:hover {
        color: rgb(76, 76, 76);
      }
    }
  }

  // タスク履歴モーダル
  &.-task-history-modal {
    position: fixed;
    top: 50vh;
    left: 50vw;
    width: 40%;
    height: 50%;
    margin-right: auto;
    margin-left: auto;
    background-color: #fff;
    border-radius: 6px;
    box-shadow: 0px 2px 5px #444;
    overflow: hidden;
    transform: translate(-50%, -50%);
    @include mixin.media-all-sp {
      width: 80%;
    }
    .task-history-modal-header {
      width: 100%;
      height: 5rem;
      background-color: #e5e5e5;
      display: flex;
      justify-content: center;
      align-items: center;

      .title {
        font-size: 13px;
        font-weight: 700;
      }

      .close-button {
        position: absolute;
        top: 10px;
        left: 10px;
        border: none;
        background-color: transparent;
        color: #7b7b7b;
        cursor: pointer;
        font-size: 16px;

        &:hover {
          color: rgb(76, 76, 76);
        }
      }
    }

    .task-history-modal-histories {
      overflow-y: auto;
      height: calc(100% - 5rem);
      .history {
        padding: 2rem;
        border-bottom: 0.5px solid #e5e5e5;
      }
    }
  }

  // 掲示板投稿モーダル
  &.-post-message-modal {
    position: fixed;
    top: 50vh;
    left: 50vw;
    width: 50rem;
    height: 41rem;
    margin-right: auto;
    margin-left: auto;
    background-color: #fff;
    border-radius: 6px;
    box-shadow: 0px 2px 5px #444;
    transform: translate(-50%, -50%);
    @include mixin.media-all-sp {
      width: 90%;
    }
    .post-message-modal-header {
      width: 100%;
      height: 5rem;
      background-color: #e5e5e5;
      display: flex;
      justify-content: center;
      align-items: center;

      .title {
        font-size: 13px;
        font-weight: 700;
      }

      .close-button {
        position: absolute;
        top: 10px;
        left: 10px;
        border: none;
        background-color: transparent;
        color: #7b7b7b;
        cursor: pointer;
        font-size: 16px;

        &:hover {
          color: rgb(76, 76, 76);
        }
      }
    }

    .post-message-modal-main {
      width: 100%;
      padding: 1rem;
      display: flex;
      justify-content: center;
    }

    .post-message-modal-remarks {
      width: 100%;
      padding: 0.5rem 4rem;
      justify-content: center;
      color: #999999;
    }

    .post-message-modal-footer {
      padding-top: 1rem;
      display: flex;
      justify-content: center;
      align-items: center;

      .post-button {
        width: 7rem;
        height: 3rem;
        padding: 1rem;
        font-weight: bold;
        font-size: 1.3rem;
        border-radius: 4px;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      .post-button-has-change {
        background-color: #0092ff;
        color: #ffffff;
      }

      .post-button-no-change {
        background-color: #c2c2c2;
        color: #ffffff;
        cursor: not-allowed;
      }
    }
  }
  // 書類サイン用モーダル
  &.-pdf-sign {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 600px;
    width: 100%;
    height: 100%;
    background-color: #f5f5f5;
    overflow: scroll;
    .modal-close-icon {
      position: absolute;
      top: 0.7rem;
      right: 0.7rem;
      cursor: pointer;
      width: 3rem;
    }
  }
}
