@use '../../../sass/foundation/mixin';

// ==================================================
// Project
// ==================================================

// Task
// ==========================================================================

.Task {
  width: 80%;

  @include mixin.media-screen-sp {
    width: 95%;
  }
  // ここからdrawer
  @at-root {
    // 定義は仮
    .Task__container {
      height: 100%;
      margin-right: auto;

      @include mixin.media-all-pc {
        padding-bottom: 6rem;
      }

      @include mixin.media-screen-sp {
        padding-bottom: 8rem;
      }

      .TaskList {
        @include mixin.media-all-pc {
          padding: 0 8.5rem;
        }

        @include mixin.media-all-tb {
          padding: 0 2rem;
        }

        @include mixin.media-screen-sp {
          padding: 0.3rem;
        }
      }
    }

    // タスクフォームのスタイル
    .Task__drawer-form {
      display: flex;
      flex-direction: column;
      height: 100%;

      .Task__drawer-container {
        flex-grow: 1;
        width: 100%;
        font-size: 1.3rem;
        padding: 3rem 4rem 0rem;

        @include mixin.media-screen-sp {
          padding-top: 1.5rem;
        }

        .Task__drawer-top {
          display: flex;
          justify-content: space-between;
          margin-bottom: 2rem;

          @include mixin.media-screen-sp {
            margin-bottom: 1rem;
          }

          .task-post-message-icon {
            width: 10rem;
            cursor: pointer;
          }

          .task-close-icon {
            cursor: pointer;
            width: 1.5rem;
          }
        }

        .Task__drawer-executor {
          display: flex;
          align-items: center;

          .icon {
            width: 8rem;
            margin-right: 1rem;

            @include mixin.media-screen-sp {
              width: 6rem;
            }
          }

          .executor {
            font-weight: bold;
            font-size: 1.6rem;

            @include mixin.media-screen-sp {
              font-size: 1.4rem;
            }
          }
        }

        .Task__drawer-title {
          width: 100%;
          margin-top: 3rem;

          @include mixin.media-screen-sp {
            margin-top: 1rem;
          }
        }

        .Task__drawer-content {
          @include mixin.media-all-pc {
            padding-top: 2rem;
            padding-right: 1rem;
            padding-left: 1rem;
            padding-bottom: 2rem;
          }

          @include mixin.media-screen-sp {
            padding-top: 0.5rem;
            padding-right: mixin.vw-value(19);
            padding-left: mixin.vw-value(19);
            padding-bottom: 0.5rem;
          }

          .Task__drawer-content-contributor {
            padding-top: 0.5rem;

            .contributor {
              margin-right: 2rem;
              margin-top: 0.5rem;
              font-size: 1rem;
              color: #333333;
            }
          }

          .Task__drawer-content-selector {
            display: flex;
            justify-content: space-around;
            padding: 1rem;

            @include mixin.media-screen-sp {
              padding-right: mixin.vw-value(19);
              padding-left: mixin.vw-value(19);
            }

            .select {
              width: 15rem;
              text-align: center;
            }

            .helper-text {
              color: red;
            }

            .Task__drawer-content-date {
              display: flex;

              .datePicker {
                width: 13rem;
              }

              .reset-button {
                cursor: pointer;
              }
            }
          }

          .Task__drawer-content-memo {
            width: 100%;
            height: 7rem;
            border: 1px solid #cccccc;
            border-radius: 4px;
            padding: 9px;
            font-size: 1rem;

            @include mixin.media-screen-sp {
              // iOSでは文字が小さいときにズームしてしまうため下記で対応
              font-size: 16px;
            }
          }

          .Task__drawer-content-file {
            position: relative;

            .Task__drawer-content-file-list {
              display: flex;
              justify-content: flex-start;
              align-items: flex-start;
              flex-wrap: wrap;
              margin-top: 0.2rem;
              margin-bottom: 0.6rem;

              li {
                position: relative;
                width: 9.5rem;
                margin-top: 1.4rem;
                margin-right: 1rem;

                &:nth-child(4n) {
                  @include mixin.media-all-tb {
                    margin-right: 0;
                  }
                }

                .Task__drawer-content-file-list-body {
                  position: relative;
                  display: flex;
                  .Task__drawer-content-file-list-body-img {
                    width: 9.3rem;
                    height: 9.5rem;
                    object-fit: cover;
                  }

                  .Task__drawer-content-file-list-body-menu {
                    position: absolute;
                    width: 30px;
                    top: 6.5rem;
                    right: 0.4rem;
                    padding: 0.3rem;
                    z-index: 250;
                    cursor: pointer;
                  }
                  .Task__drawer-content-file-list-body-balloon {
                    display: none;
                    position: absolute;
                    left: -0.5rem;
                    bottom: 4rem;
                    width: 10.8rem;
                    background-color: #000;
                    color: #fff;
                    border-radius: 0.5rem;
                    z-index: 260;

                    &.-show {
                      display: block;
                    }

                    &::after {
                      content: '';
                      position: absolute;
                      bottom: -2.2rem;
                      right: 1.5rem;
                      border-top: 12px solid #000;
                      border-right: 12px solid transparent;
                      border-left: 12px solid transparent;
                      border-bottom: 12px solid transparent;
                    }

                    span {
                      display: block;
                      margin-top: 1.5rem;
                      margin-bottom: 1.5rem;
                      padding-top: 0.6rem;
                      padding-right: 2.2rem;
                      padding-left: 2.2rem;
                      padding-bottom: 0.6rem;
                      font-size: 18px;
                      cursor: pointer;
                    }
                  }
                }

                .Task__drawer-content-file-list-name {
                  display: block;
                  width: 100%;
                  margin-top: 0.6rem;
                  font-size: 1rem;
                  word-break: break-word;
                  line-height: 2;
                  cursor: pointer;
                }

                input.FileView__item-name {
                  width: 100%;
                  margin-top: 0.6rem;
                  padding-top: 0.2rem;
                  padding-right: 0.6rem;
                  padding-left: 0.6rem;
                  padding-bottom: 0.2rem;
                  border: 1px solid #c1c1c1;
                  border-radius: 0.4rem;
                  background-color: #ffffff;
                  color: #595757;
                  font-size: 1.1rem;

                  @include mixin.media-all-tb {
                    font-size: 1.2rem;
                  }
                }

                .Task__drawer-content-file-list-menu {
                  position: absolute;
                  width: 30px;
                  top: 6.5rem;
                  right: 0.4rem;
                  padding: 0.3rem;
                  z-index: 250;
                  cursor: pointer;
                }

                & > span {
                  position: relative;
                }
              }
            }
          }

          .Task__drawer-content-look-activity-log {
            display: flex;
            justify-content: center;
            align-items: center;
            padding-bottom: 1rem;

            @include mixin.media-screen-sp {
              padding-top: 0.5rem;
            }

            .icon {
              width: 20rem;
              cursor: pointer;
            }
          }
        }
      }

      .Task__drawer-bottom {
        width: 100%;
        bottom: 0;
        height: 6rem; // 担当者を実行者にする機能を追加する際に、height: 8rem;に変更

        @include mixin.media-screen-sp {
          height: 5rem; //上記同様。7rem;に変更
        }

        .Task__drawer-bottom-notification-email {
          height: 30%;
          display: flex;
          align-items: center;
          background-color: #fbfbfb;
          font-size: large;
          justify-content: center;
          border-top: 1px solid #cccccc;
        }

        .Task__drawer-bottom-deepest-part {
          height: 100%; // 担当者を実行者にする機能を追加する際に、height: 70%;に変更
          background-color: #808080;
          display: flex;
          align-items: center;
          justify-content: space-around;
          padding: 2rem;

          .trash-box-icon {
            height: 3rem;
            width: 30%;
            cursor: pointer;
          }

          .delete-button {
            width: 30%;
            border-radius: 4px;
            padding: 10px;
            margin-top: 4px;
            background-color: #ffffff;
            color: #ff0000;
            font-weight: bold;
          }

          .save-button {
            width: 30%;
            border-radius: 4px;
            padding: 10px;
            margin-top: 4px;
            font-weight: bold;
          }

          .save-button-has-change {
            background-color: #0092ff;
            color: #ffffff;
          }

          .save-button-no-change {
            background-color: #ffffff;
            color: #b2b2b2;
            cursor: not-allowed;
          }
        }
      }
    }
  }
  // ここまでdrawer

  &__add {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 16px;
    padding: 1rem;
    height: 5rem;
    border: 1.5px solid #d6d6d6;
    border-radius: 8px;
    cursor: pointer;

    &--img {
      margin-left: 1rem;
      width: 1.8rem;
      height: 1.8rem;
    }

    span {
      padding-left: 2rem;
      font-size: 1.4rem;
      font-weight: bold;
      color: #02a4ff;
    }
  }
}

.Task__Sort {
  list-style: none;
}
